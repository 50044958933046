import React, { Suspense, lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { getCookies, removeCookies } from '../hooks/useCookies';
import Innerlayout from '../layouts/innerLayout';
import Outerlayout from '../layouts/outerLayout';
// import ViewUsers from '../pages/users/viewUser';
// import UserIndex from '../pages/users/index';
import PageNotFound from '../pages/404/pageNotFound';
import PermissionDenied from '../pages/permission/permission';

const ViewUsers = lazy(() => import('../pages/users/viewUser'));
const Login = lazy(() => import('../pages/logIn/logIn'));
const UserIndex = lazy(() => import('../pages/users/index'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const UserGroups = lazy(() => import('../pages/users/groupHistory'));
const Reports = lazy(() => import('../pages/Reports/reports'));
const ReportDetails = lazy(() => import('../pages/Reports/details'));
const PreviewContent = lazy(() => import('../pages/Reports/preview-content'));
const Feedback = lazy(() => import('../pages/Feedback/feedback'));
const FeedbackDetails = lazy(() => import('../pages/Feedback/feedback-details'));
const NewsArticles = lazy(() => import('../pages/NewsArticle/news-article'));
const NewsArticleDetails = lazy(() => import('../pages/NewsArticle/details'));
const AddNewsArticle = lazy(() => import('../pages/NewsArticle/add'));
const EditNewsArticle = lazy(() => import('../pages/NewsArticle/edit'));
const LinkExpired = lazy(() => import('../pages/link-expired'));
const GroupList = lazy(() => import('../pages/groups/groups'));
const GroupDetails = lazy(() => import('../pages/groups/group-details'));
const GroupPreview = lazy(() => import('../pages/groups/preview-group'));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/link-expired"
            element={
              <Suspense fallback={<div>...</div>}>
                <UnProtectedRoute>
                  <LinkExpired />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense fallback={<div>...</div>}>
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appusers/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appusers/groups/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserGroups />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/content-reports"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Reports />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/group-community-reports"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Reports />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user-reports"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Reports />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reports/:reportId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ReportDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reports/:reportId/preview"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PreviewContent />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/feedback"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Feedback />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/feedback/:feedbackId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FeedbackDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/news"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <NewsArticles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/news/viewdetails/:newsId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <NewsArticleDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/news/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AddNewsArticle />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/news/edit/:newsId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditNewsArticle />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/groups"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <GroupList />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/groups/:groupId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <GroupDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/groups/:groupId/preview"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <GroupPreview />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appusers"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/changePassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ChangePassword />
                </ProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              }
            >
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token');
    removeCookies('refreshToken');
    sessionStorage.setItem('cookiesCleared', true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
