/* eslint-disable no-new */
/* eslint-disable no-underscore-dangle */
import Cookies from 'universal-cookie';
import moment from 'moment';

const cookies = new Cookies();
export const getCookies = (value = '') => cookies.get(value);
export const removeCookies = (value = '') => {
  new Promise((resolve) => {
    cookies.remove(value, { sameSite: 'Strict' });
    resolve();
  });
};
export const setCookies = (key = '', value = '', remember = false) => {
  const r = localStorage.getItem('remember_user');
  if (r) {
    remember = JSON.parse(r);
  }
  let d = moment().add(1, 'day');
  // console.log('added date :: ', d);
  let options = {
    path: '/',
    sameSite: 'Strict',
    expires: new Date(d._d),
  };
  if (remember) {
    d = moment().add(1, 'year');
    options = {
      path: '/',
      sameSite: 'Strict',
      expires: new Date(d._d),
    };
  }
  cookies.set(key, value, options);
};
