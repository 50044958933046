/* eslint-disable consistent-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SERVER_URL } from './apiConstants';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Origin': '*',
};
const changepassword = 'change-password';
export const changePassword = createAsyncThunk('api/auth/change-password', async (data) => {
  const body = {
    password: data.Password,
    token: data.token,
    userid: data.userid,
    current_password: data.current_password,
  };
  return axios
    .post(`${SERVER_URL}/${changepassword}`, body, { headers })
    .then((response) => {
      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      }
    })
    .catch((e) => {
      console.log('Axios error :: ', e);
      if (e?.code === 'ERR_BAD_REQUEST') {
        throw new Error(e?.response?.data?.message);
      } else {
        throw new Error('Unknown Error');
      }
    });
});

const changePasswordSlice = createSlice({
  name: 'api',
  initialState: {
    loading: false,
    changePasswordData: {},
    error: '',
  },
  extraReducers: {
    [changePassword.pending]: (state) => {
      /* eslint-disable no-param-reassign */
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.changePasswordData = action.payload;
      toast.success('Change password successfully completed.');
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log('action payload :: ', action.error);
      toast.error(action?.error?.message);
    },
  },
});

export default changePasswordSlice.reducer;
